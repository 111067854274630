import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Show from '@components/common/condition/Show';
import { KeyboardArrowRightMedium } from '@components/svg/arrows/KeyboardArrowRightMedium';
import Menu from '@components/svg/social-media/Menu';
import Loader from '@components/widgets/loader/Loader';
import { SocialMediaSingleProfileItem } from '@pages/social-media/details/SocialMediaDetails';
import { DefinedRoutes } from '@routes/definedRoutes';

import { SocialMediaProfilesMenuItem } from './';
import './SocialMediaProfilesMenu.scss';

interface SocialMediaProfilesMenuProps {
    isLoading?: boolean;
    profiles: SocialMediaSingleProfileItem[];
    onChangeSelectedProfile: (profile: SocialMediaSingleProfileItem) => void;
}

const SocialMediaProfilesMenu = ({ isLoading = false, profiles, onChangeSelectedProfile }: SocialMediaProfilesMenuProps) => {
    const navigate                              = useNavigate();
    const [shouldShowMenu, setShouldShowMenu]   = useState(false);
    const [selectedProfile, setSelectedProfile] = useState(
        profiles.find(profile => profile.isSelected)?.id ?? null
    );
    const container                             = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!container.current) {
            return;
        }

        const handleClick = (e: MouseEvent) => onHandleCloseMenu(
            e,
            container,
            setShouldShowMenu,
        );

        window.addEventListener('click', handleClick);

        return () => {
            window.removeEventListener('click', handleClick);
        };
    }, []);

    const onBack     = () => {
        navigate(DefinedRoutes.socialMedia.path);
    };
    const toggleMenu = () => setShouldShowMenu(!shouldShowMenu);

    const setSelected = (profile: SocialMediaSingleProfileItem) => () => {
        setSelectedProfile(profile.id);
        onChangeSelectedProfile(profile);
    };

    return (
        <div className="smpm-wrapper" ref={container}>
            <div className="smpm-back smpm-button" onClick={onBack}>
                <KeyboardArrowRightMedium />
                <p>Back</p>
            </div>
            <div className="smpm-menu smpm-button" onClick={toggleMenu}>
                <Menu />

                {shouldShowMenu && (
                    <div className="smpm-menu-items-wrapper">
                        <div className="smpm-menu-items">
                            <Show.When isTrue={isLoading}>
                                <Loader />
                            </Show.When>

                            {profiles?.length ? profiles.map((profile, idx) => (
                                <SocialMediaProfilesMenuItem
                                    key={idx}
                                    {...profile}
                                    isSelected={selectedProfile === profile.id}
                                    onSelect={setSelected(profile)}
                                />
                            )) : null}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

function onHandleCloseMenu(
    event: MouseEvent,
    wrapper:  React.MutableRefObject<HTMLDivElement>,
    setShouldShowMenu: (value: boolean) => void,
) {
    if (!wrapper.current?.contains(event.target as Node)) {
        setShouldShowMenu(false);
    }
}

export default SocialMediaProfilesMenu;
