import classNames from 'classnames';

import { SocialMediaLogos } from '@constants/socialMedia';
import { SocialMediaTypes } from '@shared/enum/socialMediaTypes';

import './SocialMediaProfileAvatarSM.scss';

interface Props {
    thumbnail: string;
    networkType: SocialMediaTypes;
    className?: string;
    onClick?: () => void;
}

const NetworkTypeToLogoMapper = {
    [SocialMediaTypes.FACEBOOK]: SocialMediaLogos.facebook.filled,
    [SocialMediaTypes.INSTAGRAM]: SocialMediaLogos.instagram.normal,
    [SocialMediaTypes.TWITTER]: SocialMediaLogos.twitter.filled,
    [SocialMediaTypes.YOUTUBE]: SocialMediaLogos.youtube.normal,
};

const SocialMediaProfileAvatarSM = ({ className, thumbnail, networkType, onClick }: Props) => (
    <div className={classNames('smp-head-avatar', { [className]: !!className })} onClick={onClick} style={ onClick ? { cursor: 'pointer' } : {}}>
        <span>
            <img
                className="smp-head-thumb"
                src={thumbnail}
            />
        </span>
        <span>
            <img
                className="smp-head-network"
                src={NetworkTypeToLogoMapper?.[networkType]}
            />
        </span>
    </div>
);

export default SocialMediaProfileAvatarSM;
