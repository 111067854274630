import classNames from 'classnames';
import './SocialMediaProfileAvatar.scss';

interface Props {
    thumbnail: string;
    className?: string;
    networkThumbnail?: string;
    networkThumbnailBackground?: boolean;
}

const SocialMediaProfileAvatar = ({ className, thumbnail, networkThumbnail, networkThumbnailBackground }: Props) => (
    <div className={classNames('social-media-avatar', { noIcon: !networkThumbnail,[className]: !!className })}>
        <img src={thumbnail} alt="profile thumbnail" />

        {networkThumbnailBackground && networkThumbnail && (
            <div className="sma-social-thumb">
                <img src={networkThumbnail} alt="media thumbnail" />
            </div>
        )}
        {!networkThumbnailBackground && networkThumbnail && <img src={networkThumbnail} alt="media thumbnail" />}
    </div>
);

export default SocialMediaProfileAvatar;
