import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '@hooks/redux/useRedux';
import useErrorReporter from '@hooks/useErrorReporter';
import useGetUserSelectedSite from '@hooks/user/useGetUserSelectedSite';
import { generateNewCancelTokenSource } from '@services/axios/axiosUtils';
import { selectFromDatepicker } from '@store/date-picker/datePicker.reducer';
import { selectFromSocialMediaSingle } from '@store/social-media/single/SocialMediaSingle.slice';
import { fetchSocialMediaSingle } from '@store/social-media/single/SocialMediaSingle.thunk';

export default function useFetchNetworkData(networkId: string, shouldFetch = true) {
    const { dates, pastDates, error, status, ...data } = useAppSelector(state => ({
        ...selectFromDatepicker.dates(state),
        ...selectFromSocialMediaSingle.all(state),
    }));
    const dispatch                                     = useAppDispatch();
    const userSite                                     = useGetUserSelectedSite();

    useErrorReporter({ error, status });

    useEffect(() => {
        if (!networkId || !userSite || shouldFetch === false) {
            return;
        }

        const [from, to]               = dates;
        const [compareFrom, compareTo] = pastDates;

        const source = generateNewCancelTokenSource();

        dispatch(fetchSocialMediaSingle({
            data: {
                id: networkId,
                start_date: String(from),
                end_date: String(to),
                compareFrom: String(compareFrom),
                compareTo: String(compareTo),
                client_id: userSite.clientId,
            },
            config: {
                cancelToken: source.token
            }
        }));

        return () => {
            source && source.cancel();
        };
    }, [dates, pastDates, networkId, shouldFetch]);

    return { data, error, status };
}
