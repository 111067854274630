import { SocialMediaTypes } from '@shared/enum/socialMediaTypes';
import { isEmpty, isObjectLike } from 'lodash';

export function isInt(n) {
    return Number(n) === n && n % 1 === 0;
}

export function isFloat(n) {
    return Number(n) === n && n % 1 !== 0;
}

export const isValidNumber = (value: any) => value !== null && value !== undefined && !isNaN(value);

export const neitherNullNorUndefined = (x) => x != null && x !== undefined;

export const isInSinglePage = (locationPathname?: string) => {
    const pathname = locationPathname ? locationPathname : window?.location?.pathname;

    return pathname.includes('/content/page');
};

export const isInContentPage = (locationPathname?: string) => {
    const pathname = locationPathname ? locationPathname : window?.location?.pathname;

    return pathname.includes('/content') && !pathname.includes('/content/page');
};

export const isInAudiencePage = (locationPathname?: string) => {
    const pathname = locationPathname ? locationPathname : window?.location?.pathname;

    return pathname.includes('/audience');
};

export const isInGenderTrackerPage = (locationPathname?: string) => {
    const pathname = locationPathname ? locationPathname : window?.location?.pathname;

    return pathname.includes('/gender-tracker');
};

export const isInGenderTrackerSingleReport = (locationPathname?: string) => {
    const pathname = locationPathname ? locationPathname : window?.location?.pathname;

    return isInGenderTrackerPage(pathname) && pathname.includes('/report/');
};

export const isInSocialMediaSingle = (type: SocialMediaTypes, locationPathname?: string) => {
    const typePath = type === SocialMediaTypes.TWITTER ? 'x' : type;
    const pathname = locationPathname ? locationPathname : window?.location?.pathname;

    return pathname.includes(`/social-media/${typePath}/`);
};

export const isSingleArticle = (pageType: string) => pageType !== 'page';

export const isArrayEmpty = (value: any): boolean => !value || !(Array.isArray(value) && value.length > 0);

export const isObjectEmpty = (value: any): boolean => {
    if (!value || Array.isArray(value) || !isObjectLike(value)) {
        return true;
    }

    return isEmpty(value);
};
