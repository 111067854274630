import { SocialMediaTypes } from '@shared/enum/socialMediaTypes';
import { DefinedRoutes } from './definedRoutes';

export const getSocialMediaDetailsUrl = (type: SocialMediaTypes, id: string) => {
    const url = DefinedRoutes.socialMedia.details.path
        .replace(
            DefinedRoutes.socialMedia.details.slug[0],
            type
        ).replace(
            DefinedRoutes.socialMedia.details.slug[1],
            id
        );

    return url;
};

export const getSocialMediaSingleUrl = (type: SocialMediaTypes, id: string) => {
    if (!type) {
        throw new Error('type is mandatory');
    }

    const route = DefinedRoutes.socialMedia.singles[type];
    const url   = route.path.replace(
        route.slug[0],
        id
    );

    return url;
};

export const getGenderTrackerSingleReportUrl = (id: string, link = 'none') => {
    const url = DefinedRoutes.genderTracker.singleReport.path
        .replace(
            DefinedRoutes.genderTracker.singleReport.slug[0],
            id
        );

    return `${url}?url=${encodeURIComponent(link)}`;
};
