
const Menu = ({ fill = '#7C7C7C' }: { fill?: string }) => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
        <rect width="4.5" height="4.5" rx="1.125" fill={fill}/>
        <rect y="6.75" width="4.5" height="4.5" rx="1.125" fill={fill}/>
        <rect y="13.5" width="4.5" height="4.5" rx="1.125" fill={fill}/>
        <rect x="6.75" y="13.5" width="4.5" height="4.5" rx="1.125" fill={fill}/>
        <rect x="13.5" y="13.5" width="4.5" height="4.5" rx="1.125" fill={fill}/>
        <rect x="6.75" y="6.75" width="4.5" height="4.5" rx="1.125" fill={fill}/>
        <rect x="13.5" y="6.75" width="4.5" height="4.5" rx="1.125" fill={fill}/>
        <rect x="6.75" width="4.5" height="4.5" rx="1.125" fill={fill}/>
        <rect x="13.5" width="4.5" height="4.5" rx="1.125" fill={fill}/>
    </svg>
);

export default Menu;
