const Checked = ({ fill = '#FF6A83' }: { fill?: string }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g clipPath="url(#clip0_4541_2257)">
            <path d="M5 12L10 17L20 7" stroke={fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
            <clipPath id="clip0_4541_2257">
                <rect width="24" height="24" fill="white"/>
            </clipPath>
        </defs>
    </svg>
);

export default Checked;
