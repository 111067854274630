/* eslint-disable @typescript-eslint/naming-convention */
import { cloneDeep, isEmpty } from 'lodash';

import { generateChartXAxis, generateTooltipAndChartAxisPerDay } from '@services/chart/axis';
import { PeriodOptions } from '@shared/enum/periodOptions';
import { SocialMediaSinglePostActivity, SocialMediaSinglePostActivityChart, SocialMediaSinglePostActivityItem } from '@shared/interfaces/social-media';
import { DatePickerItem } from '@shared/interfaces/store/datePicker';

import { calcPercentageOfCurrentFromPrevious } from '@services/math/math';
import { SocialMediaSingleInteraction } from '@shared/interfaces/social-media/socialMediaSingle';
import { PostActivityThunk, PostInteractionThunk } from './SocialMediaSingle.thunk';

const placeItemByDate   = (
    array: SocialMediaSinglePostActivityItem[],
    dataItem: SocialMediaSinglePostActivityItem,
    validator?: (item: SocialMediaSinglePostActivityItem) => boolean
) => {
    for (let i = 0, len = array.length; i < len; i++) {
        const item = array[i];

        if (item?.date === dataItem?.date || validator?.(item)) {
            array[i] = dataItem;

            break;
        }
    }
};

const createInitialData = (dates: DatePickerItem, pastDates: DatePickerItem) => {
    const axisData     = generateTooltipAndChartAxisPerDay(dates, pastDates);
    const selectedYear = Number(String(dates[0]).split('-')[0]);
    const xAxis        = generateChartXAxis(axisData.currentAxis, PeriodOptions.Day, selectedYear);
    // const pastXAxis                                        = generateChartXAxis(axisData.pastAxis, PeriodOptions.Day, selectedYear);
    // const tooltipHeaders                                   = axisData.currentTooltipAxis.map((item, index) => `${item} vs ${axisData.pastTooltipAxis[index]}`);

    const initialDataItem                                  = {
        value: 0,
        date: null,
        compare: {
            pastDate: null,
            pastPercentageOrValue: 0,
            percentage: 0,
            diff: 0,
        }
    } satisfies SocialMediaSinglePostActivityItem;
    const initialData: SocialMediaSinglePostActivityItem[] = new Array(xAxis.length)
        .fill(initialDataItem)
        .map((item, idx) => ({
            ...item,
            date: axisData.currentAxisBase[idx],
            compare: {
                ...item.compare,
                pastDate: axisData.pastAxisBase[idx]
            }
        }));

    return { initialData, axisData, initialDataItem };
};

export function formatSinglePostActivity(data: PostActivityThunk, dates: DatePickerItem, pastDates: DatePickerItem): SocialMediaSinglePostActivity {
    const { initialData, initialDataItem, axisData } =createInitialData(dates, pastDates);

    if (!data) {
        return {
            chart: null,
            total: [],
            dates: axisData.currentAxisBase,
            compareDates: axisData.pastAxisBase,
        };
    }

    const formattedResult: SocialMediaSinglePostActivityChart = {};
    const total: SocialMediaSinglePostActivityItem[]          = [];

    Object.entries(data).map((([date, data]) => {
        if (isEmpty(data)) {
            return;
        }

        const dataKeys   = Object.keys(data);
        const dataValues = Object.values(data);

        total.push(
            dataValues.reduce(
                (acc, item) => ({
                    value: item.date1 + acc.value,
                    date,
                    compare: {
                        pastDate: item.compareDate,
                        pastPercentageOrValue: item.date2 + acc.compare.pastPercentageOrValue,
                        percentage: 0,
                        diff: 0,
                    }
                } as SocialMediaSinglePostActivityItem), {
                    value: 0,
                    date: '',
                    compare: {
                        pastDate: '',
                        pastPercentageOrValue: 0,
                        percentage: 0,
                        diff: 0,
                    }
                } as SocialMediaSinglePostActivityItem
            )
        );

        const dataArray =  !isEmpty(dataValues) ? dataValues.map((item, idx) => ({
            name: dataKeys?.[idx],
            value: Number(item?.date1 ?? 0),
            date: date ?? '',
            compare: {
                pastDate: item.compareDate ?? '',
                pastPercentageOrValue: Number(item?.date2 ?? 0),
                percentage: Number(item?.percentage ?? 0),
                diff: Number(item?.difference  ?? 0),
            }
        } satisfies SocialMediaSinglePostActivityItem)) : [];

        for (let i = 0,len = dataKeys.length; i < len; i++) {
            const dataName  = dataKeys[i];
            const placeItem = dataArray.find(x => x.name === dataName) || initialDataItem;

            if (!formattedResult?.[dataName] || !Array.isArray(formattedResult?.[dataName])) {
                formattedResult[dataName] = cloneDeep(initialData);

                placeItemByDate(formattedResult[dataName], placeItem);
            } else {
                placeItemByDate(formattedResult[dataName], placeItem);
            }
        }
    }));

    return {
        chart: formattedResult,
        total: total.map(
            (item) => ({
                ...item,
                compare: {
                    ...item.compare,
                    percentage: calcPercentageOfCurrentFromPrevious(item.value, item.compare.pastPercentageOrValue),
                    diff: item.value - item.compare.pastPercentageOrValue,
                }
            })
        ).reverse(),
        dates: axisData.currentAxisBase,
        compareDates: axisData.pastAxisBase,
    };
}

const InteractionPropsMapper = {
    'link video clicks': 'clicks',
    comments: 'comments',
    reactions: 'reactions',
    shares: 'shares',
};

export function formatSingleInteraction(data: PostInteractionThunk, dates: DatePickerItem, pastDates: DatePickerItem): SocialMediaSingleInteraction {
    const { initialData, axisData } =createInitialData(dates, pastDates);

    if (!data) {
        return {
            chart: null,
            dates: axisData.currentAxisBase,
            compareDates: axisData.pastAxisBase,
        };
    }

    const formattedResult: SocialMediaSinglePostActivityChart = {};
    const interactionProps                                    = Object.keys(data);
    const interactionValues                                   = Object.values(data) as unknown as PostActivityThunk[];

    interactionValues.map(((data, idx) => {
        if (isEmpty(data)) {
            return;
        }

        const dataName    = String(InteractionPropsMapper?.[interactionProps?.[idx]] ?? interactionProps?.[idx] ?? `unknown-${idx}`).toLocaleLowerCase();

        Object.entries(data).map(([date, data]) => {
            const dataItem = {
                date,
                value: Number(data.date1 || 0),
                compare: {
                    pastDate: String(data.compareDate ?? ''),
                    pastPercentageOrValue: Number(data.date2 || 0),
                    percentage: Number(data.percentage || 0),
                    diff: Number(data.difference || 0)
                }
            };

            if (!formattedResult?.[dataName] || !Array.isArray(formattedResult?.[dataName])) {
                formattedResult[dataName] = cloneDeep(initialData);
                placeItemByDate(formattedResult[dataName], dataItem, (item) => item.date === date);
            } else {
                placeItemByDate(formattedResult[dataName], dataItem, (item) => item.date === date);
            }
        });
    }));

    return {
        chart: formattedResult,
        dates: axisData.currentAxisBase,
        compareDates: axisData.pastAxisBase,
    };
}
