import { useState } from 'react';

import useToast from '@components/widgets/toast-message/useToast';
import { getErrorMessage } from '@services/error-handler/message';
import { DataStatus } from '@shared/enum/dataStatus';
import { selectFromDatepicker } from '@store/date-picker/datePicker.reducer';

import { useAppSelector } from './redux/useRedux';
import useUpdateEffect from './update-effect/useUpdateEffect';

interface UseErrorReporterProps {
    error: string | null;
    status: DataStatus;
    title?: string;
}

export default function useErrorReporter({ error, status, title }: UseErrorReporterProps) {
    const { dates, pastDates }          = useAppSelector(selectFromDatepicker.dates);
    const [hasReported, setHasReported] = useState(false);
    const { triggerToast }              = useToast({ toastId: Math.random() * (5 * 20000) });

    useUpdateEffect(() => {

        if (status === DataStatus.FAILED && error && !hasReported) {
            const message = (() => {
                if (title) {
                    return `${title}: ${String(getErrorMessage(error)).toLocaleLowerCase()}`;
                }

                return String(getErrorMessage(error));
            })();

            triggerToast(message, 'error');
            setHasReported(true);

            return;
        }

        setHasReported(false);
    }, [error, status]);

    useUpdateEffect(() => { setHasReported(false); }, [dates, pastDates]);

    return hasReported;
}
