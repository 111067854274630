import { useCallback } from 'react';

import Checked from '@components/svg/Checked';

import SocialMediaProfileAvatar from '../avatar/SocialMediaProfileAvatar';
import './SocialMediaProfilesMenuItem.scss';

export interface SocialMediaProfileMenuItemProps {
    thumbnail: string;
    networkThumbnail?: string;
    profile: string;
    isSelected: boolean;
    onSelect: () => void;
}

const SocialMediaProfilesMenuItem = ({
    thumbnail, networkThumbnail, profile,
    isSelected, onSelect
}: SocialMediaProfileMenuItemProps) => {
    const onClick = useCallback(onSelect, [onSelect]);

    return (
        <div className="smpmi-container" onClick={onClick}>
            <div className="smpmi-left">
                <SocialMediaProfileAvatar
                    thumbnail={thumbnail}
                    networkThumbnail={networkThumbnail}
                />
                <h4>{profile}</h4>
            </div>

            {isSelected && <Checked />}
        </div>
    );
};

export default SocialMediaProfilesMenuItem;
