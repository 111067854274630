import { SocialMediaAPIClient } from '@services/axios/clients/socialMediaAPIClient';
import { createAsyncThunkWithClient } from '@services/redux/createAsyncThunkWithClient';
import { SocialMediaTypes } from '@shared/enum';
import { ActionError } from '@shared/enum/actionError';
import { ComparativeApiChartData } from '@shared/interfaces/comparative-chart-data';
import { CumulativeApiChartData } from '@shared/interfaces/cumulative-chart-data';
import { isEmpty, isObjectLike } from 'lodash';

export interface SocialMediaOverviewProfile {
    networkType: SocialMediaTypes;
    profileName: string;
    engagement: {
        value: number;
        pastValue: number;
        percentage: number;
    };
    engagementRate: {
        value: number;
        pastValue: number;
        percentage: number;
    };
    followers: {
        total: string;
        pastValue: number;
        percentage: number;
    };
    interactions: {
        total: string;
        pastValue: string;
        difference: string;
    };
    posts: {
        total: string;
        pastValue: string;
        percentage: string;
    };
    postsImpressions: {
        value: number;
        pastValue: number;
        percentage: number;
    };
    postsReach: {
        value: number;
        pastValue: number;
        percentage: number;
    };
}
export interface SocialMediaOverviewProfiles {
    [profileId: string]: SocialMediaOverviewProfile;
}

export interface AppleObjValue {
    pastValue: number;
    percentage: number;
    value: number;
}

export interface AppleChart {
    comparative: ComparativeApiChartData;
    cumulative: CumulativeApiChartData;
}

interface PostHighlight {
    image: string;
    link: string;
    networkType: SocialMediaTypes;
    value: string;
    thumbnail: string;
}

export interface SocialMediaOverviewReturn {
    engagementHistory: AppleChart;
    impressionHistory: AppleChart;
    postInteractions: AppleChart;

    mostEngagementPost: PostHighlight;
    mostImpressionPost: PostHighlight;
    leastEngagementPost: PostHighlight;
    leastImpressionPost: PostHighlight;

    profiles: SocialMediaOverviewProfiles;

    totalComments: AppleObjValue;
    totalEngagement: AppleObjValue;
    totalFollowers: AppleChart;
    totalImpression: AppleObjValue;
    totalInteractions: AppleObjValue;
    totalPosts: AppleObjValue;
    totalReactions: AppleObjValue;
    totalShares: AppleObjValue;
    postsReach: AppleObjValue;
    totalEngagementRate: AppleObjValue;
}

interface SocialMediaOverviewParams {
    start_date: string;
    end_date: string;
    compareFrom: string;
    compareTo: string;
    client_id: string;
}

export const fetchSocialMediaOverview = createAsyncThunkWithClient<SocialMediaOverviewReturn, SocialMediaOverviewParams>({
    requestFn: (params, config) => SocialMediaAPIClient.getOverview<SocialMediaOverviewParams>(
        params,
        config
    ),
    typePrefix: 'socialMediaOverview/all'
});

export interface SocialMediaOverviewEngagementReturn {
    sparklines: Record<string, string>;
    total: string;
    percentage: string;
}

function instanceOfSocialMediaOverviewEngagementReturn(dataObject: any): dataObject is SocialMediaOverviewEngagementReturn {
    return (
        dataObject &&
        isObjectLike(dataObject) &&
        !Array.isArray(dataObject) &&
        'sparklines' in dataObject &&
        'total' in dataObject &&
        'percentage' in dataObject
    );
}

export const fetchSocialMediaOverviewEngagement = createAsyncThunkWithClient<SocialMediaOverviewEngagementReturn, SocialMediaOverviewParams>({
    requestFn: (params, config) => SocialMediaAPIClient.getOverviewEngagement<SocialMediaOverviewParams>(
        params,
        config
    ),
    addDataCheck: (data) => {
        if (isEmpty(data) || !instanceOfSocialMediaOverviewEngagementReturn(data)) {
            throw new Error(ActionError.NoSocialMediaProfile);
        }

        return data;
    },
    typePrefix: 'socialMediaOverview/engagement'
});
