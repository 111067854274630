import { useNavigate } from 'react-router-dom';

import Img from '@components/common/img/Img';
import { ComponentInfo } from '@components/component-info';
import { KeyboardArrowRightMedium } from '@components/svg/arrows/KeyboardArrowRightMedium';
import './PageNav.scss';

interface Props {
    parent: string;
    childrenPages: {
        name: string;
        image?: string;
        path?: string;
        tooltip?: string;
        tooltipPosition?: {
            left?: string;
            right?: string;
            top?: string;
            bottom?: string;
        };
    }[];
}

const PageNav = ({ parent, childrenPages }: Props) => {
    const navigate = useNavigate();

    const onNavigate = (path: string) => {
        if (!path) {
            return;
        }

        navigate(path);
    };

    return (
        <div className='page-nav'>
            <h2>{parent}</h2>
            {childrenPages.map(({ name, path = null, image = null, tooltip = null, tooltipPosition = null }, idx) => (
                <div className='page-nav-item' key={`${name}-${idx}`}>
                    <KeyboardArrowRightMedium />
                    <div
                        className='page-nav-btn'
                        style={{ cursor: path ? 'pointer' : '' }}
                        onClick={() => onNavigate(path)}
                    >
                        {image && <Img src={image} alt='country icon' />}
                        <p>{name}</p>
                        {tooltip && <ComponentInfo
                            tooltipContent={tooltip}
                            tooltipPosition={tooltipPosition || {
                                top: '15px',
                                left: '-10px',
                            }}
                        />}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default PageNav;
