import type { DatePickerItem } from 'shared/interfaces/store/datePicker';
import type { TooltipParamCompare } from 'shared/interfaces/tooltip/tooltipParamCompare';

import { ComparisonColors, ComparisonIcons } from 'constants/comparison';
import { CHART_TOOLTIP_STYLE_2 } from 'constants/tooltip';
import logger from 'middleware/logger';
import { parse, symbolizeNumber } from 'services/conversion/conversion';
import DateInstance from 'services/date/date';
import { PeriodOptions } from 'shared/enum/periodOptions';
import { TooltipHeaderDateInBetweenOptions } from 'shared/enum/tooltipHeaderDateInBetweenOptions';

/**
 * @todo remove getColor() & getLogo() and replace all usage of
 * these function to getColorBase() and getLogoBase()
 */

// default tooltip colors/icons
const color                  = ComparisonColors;
const { decrease, increase } = ComparisonIcons;

/**
 *
 * Generates the tooltip compare color
 *
 * @param {Object} Options
 * @param {String} Options.percentage percentage value
 * @param {Number} Options.current current value
 * @param {Number} Options.previous previous(compared with) value
 * @returns {String} color
 */
export const getColor = ({
    percentage,
    current,
    previous,
    percent
}: {
    percentage: string;
    current: number;
    previous: number;
    percent?: number;
}): string => {
    if (percent != null) {
        return percent >= 0 ? color.green : color.red;
    }

    const prev  = previous ? previous : 0;
    const isRed = (percentage === '0%' || percentage === '0.0%') && color.green;
    const diff  = current < prev ? color.red : color.green;

    return isRed || diff;
};

export const getColorBase = (percentage: number): string => {
    if (!percentage){
        return color.gray;
    }

    return percentage > 0 ? color.green : color.red;
};

/**
 *
 * Generates the tooltip compare logo
 *
 * @param {Object} Options
 * @param {Number} Options.current current value
 * @param {Number} Options.previous previous(compared with) value
 * @param {Number} Options.percent if passed, the logo will be returned based on the value of the percentage.
 * @returns {String}  logo
 */
export const getLogo = ({
    current,
    previous,
    percent
}: {
    current: number;
    previous: number;
    percent?: number;
}): string => {
    if (percent != null) {
        return percent >= 0 ? increase : decrease;
    }

    const prev   = previous ? previous : 0;
    const isNull = prev - current === 0 && increase;
    const diff   = current > prev ? increase : decrease;

    return isNull || diff;
};

export const getLogoBase = (percentage: number): string => {
    if (!percentage){
        return null;
    }

    return percentage > 0 ? increase : decrease;
};


/**
 * @todo calcCurrentPrevious and formatPlusMinus should be removed
 */

/**
 *
 * Generates the difference(%) between current and previous
 *
 * @param {Object} Options
 * @param {Number} Options.current current value
 * @param {Number} Options.previous previous(compared with) value
 * @param {Number} Options.digits percentage value(default=1)
 * @returns {String} difference percentage
 */
export const calcCurrentPrevious = ({
    current,
    previous,
    digits,
}: {
    current: number;
    previous: number;
    digits?: number;
}): string => {
    const fixedTo = digits || 1;
    const curr    = current || 0;
    const prev    = previous || 0;

    const diffPercent = (curr - prev) * 100;
    const res         = diffPercent / prev;

    return res.toFixed(fixedTo) + '%';
};

/**
 *
 * Formats a number
 *
 * example:
 * - (+50)
 * - (-56)
 *
 * @param {Number} number number to format
 * @param {Number} digits number of digits after the decimal point
 * @returns {String} formatted number
 */
export function formatPlusMinus(number: number, digits = 1): string {
    if (!number) {return '';}

    const [isMinus] = number
        .toString()
        .split('')
        .filter((x) => x === '-');

    if (isMinus) {return '(-' + symbolizeNumber(-1 * number, 1, { convertToComma: true, shouldRound: true, ignoreFirstSixDigits: true }) + ')';}

    if (number % 1 !== 0) {return '(+' + parse.intFloat(number, digits) + ')';}

    return '(+' + number + ')';
}

export const getNeutralLogo = () => (`
    <span style='${CHART_TOOLTIP_STYLE_2.neutralLogo}'>
        <span style='transform: translateY(-2px)'>
            -
        </span>
    </span>
`);

export const getCompareLogo = (percentage) => (
    percentage === 0 ? getNeutralLogo() : (
        `<img style='${CHART_TOOLTIP_STYLE_2.differenceLogo}' src='${getLogoBase(percentage)}' alt='tooltip arrow logo'/>`
    )
);

export const getTooltipHeaderDate = (dates: DatePickerItem, selectedPeriod?: PeriodOptions) => {
    try {
        const dateFrom = DateInstance.parseDate(String(dates[0]),'yyyy-MM-dd', DateInstance.newDate());
        const dateTo   = DateInstance.parseDate(String(dates[1]),'yyyy-MM-dd', DateInstance.newDate());

        switch (selectedPeriod){
            case PeriodOptions.Week:
                return DateInstance.formatDate({ date: dateFrom, format: 'dd MMM' })
                        + ' ' + TooltipHeaderDateInBetweenOptions.To + ' ' +
                        DateInstance.formatDate({ date: dateTo, format: 'dd MMM' });
            case PeriodOptions.Month:
                return DateInstance.formatDate({ date: dateFrom, format: 'MM' })
                        + ' ' + TooltipHeaderDateInBetweenOptions.To + ' ' +
                        DateInstance.formatDate({ date: dateTo, format: 'MM' });
            default:
                return DateInstance.formatDate({ date: dateFrom, format: 'E. dd MMM' })
                        + ' ' + TooltipHeaderDateInBetweenOptions.To + ' ' +
                        DateInstance.formatDate({ date: dateTo, format: 'E. dd MMM' });
        }
    } catch (error) {
        logger.error(error);
    }
};

export const getTooltipHeaderDateWithComparison = (
    date: string | number,
    compareDate: string | number,
    selectedPeriod?: PeriodOptions,
) => {
    try {
        switch (selectedPeriod){
            case PeriodOptions.Week:
                return date + ' ' + TooltipHeaderDateInBetweenOptions.Vs + ' ' + compareDate;
            case PeriodOptions.Month:
                return date + ' ' + TooltipHeaderDateInBetweenOptions.Vs + ' ' + compareDate;
            default:
                return date + ' ' + TooltipHeaderDateInBetweenOptions.Vs + ' ' + compareDate;
        }
    } catch (error) {
        logger.error(error);
    }
};

export function getCompareSection(compare: TooltipParamCompare, reverseComparisonLogic) {
    const { pastPercentageOrValue, diff } = compare;
    let { percentage }                    = compare;

    percentage                            = reverseComparisonLogic ? -percentage : percentage;

    const displayedDiff    = diff != null
        ? symbolizeNumber(Math.abs(diff), 1, { convertToComma: true, shouldRound: true, ignoreFirstSixDigits: false })
        : 0;
    const parsedPercentage = parse.intFloat(percentage, 1);
    const args             = {
        displayedDiff: diff > 0 ? `+${displayedDiff}` : `-${displayedDiff}`,
        augmentLogo: getCompareLogo(parsedPercentage),
        augmentColor: getColorBase(parsedPercentage)
    };

    if (pastPercentageOrValue === 0){
        return {
            ...args,
            displayedPercentage: `<span style='color: ${ComparisonColors.gray};transform: translateY(1px)'>-</span>`
        };
    }

    const displayedPercentage = parsedPercentage > 0 ? `+${parsedPercentage}%` : `${parsedPercentage}%`;

    return {
        ...args,
        displayedPercentage: parsedPercentage ? displayedPercentage : '0%'
    };
}
