import { useAppDispatch, useAppSelector } from '@hooks/redux/useRedux';
import useErrorReporter from '@hooks/useErrorReporter';
import useGetUserSelectedSite from '@hooks/user/useGetUserSelectedSite';
import { generateNewCancelTokenSource } from '@services/axios/axiosUtils';
import { selectFromSocialMediaSingle } from '@store/social-media/single/SocialMediaSingle.slice';
import { fetchSocialMediaSingleActiveProfiles } from '@store/social-media/single/SocialMediaSingle.thunk';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function useFetchActiveProfiles(profileId?: string) {
    const { pathname }                       = useLocation();
    const { activeProfiles, error,  status } = useAppSelector(
        selectFromSocialMediaSingle.activeProfiles
    );
    const dispatch                           = useAppDispatch();
    const userSite                           = useGetUserSelectedSite();

    useErrorReporter({ error, status });

    useEffect(() => {
        const source = generateNewCancelTokenSource();

        dispatch(fetchSocialMediaSingleActiveProfiles({
            data: {
                clientId: userSite.clientId,
            },
            config: {
                cancelToken: source.token
            }
        }));

        return () => {
            source && source.cancel();
        };
    }, [profileId, pathname]);

    return { activeProfiles, error, status };
}
